<template>
  <div class="flightsDashboard js-tabBox flightsDashboard--provider">
    <vue-scroll :ops="ops">
      <div class="flightsDashboard__header">
        <div class="flightsDashboard__title">Flights</div>
        <div class="flightsDashboard__nav">
          <div class="flightsDashboard__nav-item js-tabNavItem" @click="tabActivate">
            <span class="text">Current</span>
          </div>
          <div class="flightsDashboard__nav-item js-tabNavItem" @click="tabActivate">
            <span class="text">Historical</span>
          </div>
          <div class="flightsDashboard__nav-item js-tabNavItem" @click="tabActivate">
            <span class="text">Upcoming</span>
          </div>
        </div>
      </div>
      <div class="flightsDashboard__content">
        <div class="flightsDashboard__content-inner js-customScroll">
          <div class="flightsDashboard__content-item js-tabContentItem">
            <div class="flightsDashboard__list">
              <div
                v-for="item of flights.current"
                :key="item.id"
                class="flightsDashboard__list-item"
              >
                <div class="flightsDashboard__list-item-left">
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Flight confirmation number</div>
                    <router-link class="flightsDashboard__list-item-link" to="#">{{item.flightNumber}}</router-link>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Departure date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.departureDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking number</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingNumber}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Take-off</div>
                    <div class="flightsDashboard__list-item-text">{{item.takeOff}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Arrival date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.arrivalDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking flyer's name</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingName}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Land</div>
                    <div class="flightsDashboard__list-item-text">{{item.land}}</div>
                  </div>
                </div>
                <div class="flightsDashboard__list-item-right">
                  <router-link
                    class="flightsDashboard__list-item-link"
                    to="flights/detail"
                  >
                    See full flight detail
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="flightsDashboard__content-item js-tabContentItem">
            <div class="flightsDashboard__list">
              <div
                v-for="item of flights.historical"
                :key="item.id"
                class="flightsDashboard__list-item"
              >
                <div class="flightsDashboard__list-item-left">
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Flight confirmation number</div>
                    <router-link class="flightsDashboard__list-item-link" to="#">{{item.flightNumber}}</router-link>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Departure date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.departureDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking number</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingNumber}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Take-off</div>
                    <div class="flightsDashboard__list-item-text">{{item.takeOff}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Arrival date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.arrivalDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking flyer's name</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingName}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Land</div>
                    <div class="flightsDashboard__list-item-text">{{item.land}}</div>
                  </div>
                </div>
                <div class="flightsDashboard__list-item-right">
                  <router-link class="flightsDashboard__list-item-link" to="#">See full flight detail</router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="flightsDashboard__content-item js-tabContentItem">
            <div class="flightsDashboard__list">
              <div
                v-for="item of flights.upcoming"
                :key="item.id"
                class="flightsDashboard__list-item"
              >
                <div class="flightsDashboard__list-item-left">
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Flight confirmation number</div>
                    <router-link class="flightsDashboard__list-item-link" to="#">{{item.flightNumber}}</router-link>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Departure date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.departureDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking number</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingNumber}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Take-off</div>
                    <div class="flightsDashboard__list-item-text">{{item.takeOff}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Arrival date, time</div>
                    <div class="flightsDashboard__list-item-text">{{item.arrivalDate}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Booking flyer's name</div>
                    <div class="flightsDashboard__list-item-text">{{item.bookingName}}</div>
                  </div>
                  <div class="flightsDashboard__list-item-info">
                    <div class="flightsDashboard__list-item-title">Land</div>
                    <div class="flightsDashboard__list-item-text">{{item.land}}</div>
                  </div>
                </div>
                <div class="flightsDashboard__list-item-right">
                  <router-link class="flightsDashboard__list-item-link" to="#">See full flight detail</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
  </div>
</template>

<script>
  import { Tabs } from '@/js/plugins/tabs'
  import './FlightsProvider.styl'

  export default {
    name: 'FlightsProvider',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      flights () {
        return this.$store.state.flightsProvider.flights
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)
      },
    },
  }
</script>
